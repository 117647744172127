import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import { z } from "zod";

export const loginSchema = z.object({
  email: z.string().email("Please enter a valid email"),
  password: z.string().min(6, "Password must be at least 6 characters"),
  savedFavoriteVehicles: z.array(z.string().uuid("Invalid vehicle ID format")).optional(),
});

export const otpLoginSchema = z.object({
  phone: z.string().min(10, "Phone number is required"),
  code: z.string().length(6, "OTP must be 6 digits"),
  savedFavoriteVehicles: z.array(z.string().uuid("Invalid vehicle ID format")).optional(),
});

export const checkPhoneNumberSchema = z.object({
  phone: z
    .string()
    .min(8, "Phone number is required")
    .refine((value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value);
      if (!phoneNumber) return false;
      return isValidPhoneNumber(value);
    }, "Please enter a valid phone number"),
});

export const optSendSchema = z.object({
  phone: z.string().min(10, "Phone number is required"),
  isRegistration: z.boolean().optional(),
});

export const registerSchema = z
  .object({
    name: z.string().min(2, "Full name is required"),
    email: z.string().email("Please enter a valid email").optional().nullable(),
    password: z.string().min(6, "Password must be at least 6 characters").optional(),
    phone: z.string().min(10, "Phone number is required"),
    confirmPassword: z.string().optional(),
    savedFavoriteVehicles: z.array(z.string().uuid("Invalid vehicle ID format")).optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export type LoginInput = z.infer<typeof loginSchema>;
export type RegisterInput = z.infer<typeof registerSchema>;
export type OtpLoginInput = z.infer<typeof otpLoginSchema>;
export type CheckPhoneNumberInput = z.infer<typeof checkPhoneNumberSchema>;
export type OptSendInput = z.infer<typeof optSendSchema>;
