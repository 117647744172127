"use client";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  buttonText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading, buttonText, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading || props.disabled}
        {...props}
      >
        {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
        {buttonText || children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

// Custom Button Component
interface CustomButtonProps {
  textColor?: string;
  bgColor?: string;
  type?: "button" | "submit" | "reset";
  loadingState?: boolean;
  buttonText: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void | Promise<void>;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  textColor = "text-white",
  bgColor = "red",
  type = "button",
  buttonText,
  loadingState,
  className,
  disabled,
  onClick,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const bgClass = bgColor.startsWith("var(--") ? `bg-[${bgColor}]` : `bg-${bgColor}`;
  const textClass = textColor.startsWith("var(--") ? `text-[${textColor}]` : `text-${textColor}`;

  const handleClick = async (e: React.MouseEvent) => {
    if (!onClick) return;

    try {
      setIsLoading(true);
      await Promise.resolve(onClick(e));
    } catch (error) {
      console.error("Button onClick error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={isLoading || loadingState || disabled}
      className={clsx("cursor-pointer rounded-xl font-inter text-sm font-semibold", bgClass, textClass, className)}
      tabIndex={0}
      type={type}
    >
      <span className="flex items-center justify-center">
        {isLoading || loadingState ? <Loader2 className="h-4 w-4 animate-spin" /> : buttonText}
      </span>
    </button>
  );
};
export { Button, buttonVariants };
export default CustomButton;
