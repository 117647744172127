import { useState, useEffect } from "react";

interface UseLocalStorageValueOptions<T> {
  key: string;
  defaultValue: T;
  // Optional serialization functions
  serialize?: (value: T) => string;
  deserialize?: (value: string) => T;
}

export function useLocalStorageValue<T>({
  key,
  defaultValue,
  serialize = JSON.stringify,
  deserialize = JSON.parse,
}: UseLocalStorageValueOptions<T>) {
  // Separate state for the value and loading status
  const [value, setValue] = useState<T>(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  // Load value on mount
  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        setValue(deserialize(item));
      }
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
    } finally {
      setIsLoading(false);
    }
  }, [key, deserialize]);

  // Update localStorage when value changes
  const updateValue = (newValue: T | ((prev: T) => T)) => {
    try {
      // Handle function updates
      const valueToStore = newValue instanceof Function ? newValue(value) : newValue;
      setValue(valueToStore);
      window.localStorage.setItem(key, serialize(valueToStore));
    } catch (error) {
      console.error(`Error saving to localStorage key "${key}":`, error);
    }
  };

  const deleteValue = () => {
    window.localStorage.removeItem(key);
    setValue(defaultValue);
  };

  return { value, setValue: updateValue, isLoading, deleteValue };
}
