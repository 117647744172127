"use client";

import { UserGetDTO } from "@alba-cars/common-modules";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { signOut, useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useLocalStorageValue } from "@/hooks/useLocalStorageValue";
import { getMyProfile } from "@/lib/api/user/get-my-profile";
import { FAVORITES_KEY } from "@/lib/utils";
import { QUERY_KEYS } from "@/utils/constants";

interface AuthContextType {
  user: Session["user"] | null;
  profile: UserGetDTO | null;
  token: string | null;
  logout: () => Promise<void>;
  updateAuth: (data: Session) => Promise<void>;
  clearAuth: () => Promise<void>;
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  refreshProfile: () => Promise<void>;
  isProfileLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { data: session, status } = useSession();
  const [user, setUser] = useState<Session["user"] | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const { deleteValue: deleteLocalFavorites } = useLocalStorageValue<Set<string>>({
    key: FAVORITES_KEY,
    defaultValue: new Set(),
  });

  const isAuthLoading = status === "loading";

  useEffect(() => {
    if (status === "authenticated" && session?.user) {
      setUser(session.user);
      setToken(session.user.accessToken || null);
    } else if (status === "unauthenticated") {
      setUser(null);
      setToken(null);
    }
  }, [session, status]);

  const updateAuth = async (data: Session) => {
    setUser(data.user);
    setToken(data.user.accessToken || null);
  };

  const clearAuth = async () => {
    setUser(null);
    setToken(null);
  };

  const logout = async () => {
    await signOut({ callbackUrl: "/" });
    deleteLocalFavorites();
  };

  const isAuthenticated = !!user && !!token;

  const queryResult = useQuery({
    queryKey: [QUERY_KEYS.USER_PROFILE, user?.id],
    queryFn: () => getMyProfile(user?.id ?? "", token ?? undefined),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const profile = queryResult.data ?? null;
  const refreshProfile = async () => {
    await queryResult.refetch();
  };
  const isProfileLoading = queryResult.isLoading;

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        logout,
        updateAuth,
        clearAuth,
        isAuthenticated,
        isAuthLoading,
        profile,
        refreshProfile,
        isProfileLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
