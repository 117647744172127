/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable prettier/prettier */
import {
  apiRequest,
  AUTH_ENDPOINTS,
  AuthResponseDTO,
  DataReturnType,
  timeAsMilliseconds,
} from "@alba-cars/common-modules";
import NextAuth, { DefaultSession } from "next-auth";
import { JWT } from "next-auth/jwt";
import CredentialsProvider from "next-auth/providers/credentials";

import { loginSchema, otpLoginSchema } from "./schemas/validation/authSchemas";

declare module "next-auth/jwt" {
  interface JWT {
    expiresAt?: string;
    phone?: string;
    accessToken?: string;
    refreshToken?: string;
    id?: string;
    isPhoneNumberVerified?: boolean;
  }
}

declare module "next-auth" {
  interface Session {
    user: {
      id: string;
      phone?: string;
      accessToken?: string;
      refreshToken?: string;
      isPhoneNumberVerified?: boolean;
    } & DefaultSession["user"];
    error?: unknown;
  }

  interface User {
    phone?: string;
    accessToken?: string;
    refreshToken?: string;
    expiresAt?: string;
    isPhoneNumberVerified?: boolean;
  }
}

export const { handlers, auth, signIn, signOut } = NextAuth({
  providers: [
    CredentialsProvider({
      id: "traditional",
      name: "Credentials",
      credentials: {
        email: { label: "Email", type: "email" },
        password: { label: "Password", type: "password" },
        savedFavoriteVehicles: { label: "Saved Favorite Vehicles", type: "array" },
      },
      async authorize(credentials) {
        try {
          let savedFavoriteVehicles: string[] | undefined;
          if (credentials?.savedFavoriteVehicles) {
            try {
              savedFavoriteVehicles =
                typeof credentials.savedFavoriteVehicles === "string" && credentials.savedFavoriteVehicles.length > 0
                  ? credentials.savedFavoriteVehicles.split(",")
                  : [];
            } catch (e) {
              console.warn("Failed to parse savedFavoriteVehicles:", e);
              savedFavoriteVehicles = undefined;
            }
          }

          const validatedData = loginSchema.parse({
            ...credentials,
            savedFavoriteVehicles,
          });
          const response = await apiRequest<
            DataReturnType<
              AuthResponseDTO & {
                accessToken: string;
                refreshToken: string;
                expiresAt: number;
                isPhoneNumberVerified: boolean;
              }
            >
          >(AUTH_ENDPOINTS.login.toString(), {
            method: "POST",
            body: JSON.stringify(validatedData),
          });

          if (!response.success || !response.data?.user.id) {
            return null;
          }

          return {
            id: response.data.user.id,
            name: response.data.user.name || "",
            email: response.data.user.email || "",
            phone: response.data.user.phone || "",
            image: response.data.user.photo || "",
            accessToken: response.data.token || response.data.accessToken || "",
            refreshToken: response.data.refreshToken || "",
            isPhoneNumberVerified: (response.data.user as any).isPhoneNumberVerified || false,
            expiresAt: response.data.expiresAt
              ? new Date(response.data.expiresAt).toISOString()
              : new Date(Date.now() + timeAsMilliseconds({ hours: 1 })).toISOString(),
          };
        } catch (error) {
          console.error("Authorization error", error);
          return null;
        }
      },
    }),
    CredentialsProvider({
      id: "otp",
      name: "Otp",
      credentials: {
        phone: { label: "Phone", type: "text" },
        code: { label: "Code", type: "text" },
        savedFavoriteVehicles: { label: "Saved Favorite Vehicles", type: "array" },
      },
      async authorize(credentials) {
        try {
          let savedFavoriteVehicles: string[] | undefined;
          if (credentials?.savedFavoriteVehicles) {
            try {
              savedFavoriteVehicles =
                typeof credentials.savedFavoriteVehicles === "string" && credentials.savedFavoriteVehicles.length > 0
                  ? credentials.savedFavoriteVehicles.split(",")
                  : [];
            } catch (e) {
              console.warn("Failed to parse savedFavoriteVehicles:", e);
              savedFavoriteVehicles = undefined;
            }
          }
          const validatedData = otpLoginSchema.parse({
            ...credentials,
            savedFavoriteVehicles,
          });

          const response = await apiRequest<
            DataReturnType<
              AuthResponseDTO & {
                refreshToken: string;
                accessToken: string;
                expiresAt: number;
                isPhoneNumberVerified: boolean;
              }
            >
          >("/auth/phone/verify-and-login", {
            method: "POST",
            body: JSON.stringify(validatedData),
          });

          if (!response.success || !response.data?.user.id) {
            return null;
          }

          return {
            id: response.data.user.id,
            name: response.data.user.name || "",
            email: response.data.user.email || "",
            phone: response.data.user.phone || "",
            image: response.data.user.photo || "",
            accessToken: response.data.accessToken || response.data.token || "",
            refreshToken: response.data.refreshToken || "",
            isPhoneNumberVerified: (response.data.user as any).isPhoneNumberVerified || false,
            expiresAt: response.data.expiresAt
              ? new Date(response.data.expiresAt).toISOString()
              : new Date(Date.now() + timeAsMilliseconds({ hours: 1 })).toISOString(),
          };
        } catch (error) {
          console.error("Authorization error", error);
          return null;
        }
      },
    }),
  ],
  callbacks: {
    async jwt({ token, user }) {
      if (user) {
        return {
          ...token,
          id: user.id,
          phone: user.phone,
          isPhoneNumberVerified: user.isPhoneNumberVerified,
          accessToken: user.accessToken,
          refreshToken: user.refreshToken,
          expiresAt: user.expiresAt,
        } as JWT;
      }

      const expiresAt = token?.expiresAt
        ? new Date(token.expiresAt).getTime()
        : new Date(Date.now() + timeAsMilliseconds({ hours: 1 })).getTime();

      const hasTokenExpired = expiresAt < Date.now();

      if (!hasTokenExpired) {
        return token;
      }
      try {
        const response = await apiRequest<
          DataReturnType<AuthResponseDTO & { accessToken: string; refreshToken: string }>
        >("/auth/refresh-token", {
          method: "POST",
          body: JSON.stringify({ refreshToken: token.refreshToken }),
        });
        if (!response.success) {
          throw new Error("Failed to refresh token");
        }

        if (!response.data?.accessToken || !response.data?.refreshToken) {
          throw new Error("Failed to refresh token");
        }

        return {
          ...token,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          expiresAt: response.data.expiresAt,
        } as JWT;
      } catch (error) {
        // console.error("Token refresh error:", error);
        return { ...token, error: "RefreshAccessTokenError" } as JWT;
      }
    },
    async session({ session, token }) {
      session.user = {
        ...session.user,
        ...token,
        id: token.sub as string,
        email: token.email as string,
        name: token.name,
        phone: token.phone as string | undefined,
        isPhoneNumberVerified: token.isPhoneNumberVerified as boolean | undefined,
        image: token.picture as string | null | undefined,
        accessToken: token.accessToken as string | undefined,
        refreshToken: token.refreshToken as string | undefined,
      };
      session.error = token.error;

      return session;
    },
  },
  pages: {
    signIn: "/login",
    signOut: "/auth/logout",
  },
  session: {
    strategy: "jwt",
  },
  secret: process.env.NEXTAUTH_SECRET,
  trustHost: true,
});

export const AUTH_TYPES = {
  TRADITIONAL: "traditional",
  OTP: "otp",
} as const;

export type AuthType = (typeof AUTH_TYPES)[keyof typeof AUTH_TYPES];
