import { apiRequest, UserGetDTO } from "@alba-cars/common-modules";

import { auth } from "@/auth";

export const getMyProfile = async (id: string, token?: string) => {
  try {
    let accessToken = token;

    if (!accessToken) {
      const session = await auth();
      accessToken = session?.user?.accessToken;
    }
    const endpoint = `/user/${id}`;

    return apiRequest<UserGetDTO>(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error("Error getting my profile:", error);
    throw error;
  }
};
